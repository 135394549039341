import { createRouter,createWebHashHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import PrivacyPage from  '../views/PrivacyPage.vue'
import OfficialWebPage from  '../views/OfficialWebPage.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  }
  ,
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage
  },
  {
    path: '/officialWebPage',
    name: 'OfficialWebPage',
    component: OfficialWebPage
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),//createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
